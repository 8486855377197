import React from 'react'
import { Footer } from './components/Footer'
import Header from './components/Header'
import './privacy.css';

export const Privacy = () => {
  return (
    <>
        <Header />
        <section>
            <div className='container'>
                <div className='web-container'>
                    <div className='title' data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                        <h2>Privacy policy</h2>
                    </div>
                    <div className='webcontent' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine" data-aos-delay="100">
                        <p>Ameya Commercial Projects Private Limited and its group companies (hereinafter referred to as “Ameya Group” “us” “we” or “our”) is committed to respecting your privacy and to complying with applicable data protection and privacy laws of India.</p>
                        <p className='m-0 mt-4'><strong>Acceptance</strong></p>
                        <p>By using this website, you signify your acceptance to Ameya Group Privacy Policy. While information is the cornerstone of our ability to provide superior service, our most important asset is our customer’s trust. Keeping our customer’s information secure and using it only as per our customer’s requirement, has been a top priority for all of us at Ameya Group. We will safeguard all information of our customers that has been shared with us in accordance with the standards of security as per the applicable laws prevalent in India. We use your Personal information only for providing and improving the site. By using the site, you agree to the collection and use of information in accordance with this Policy. We compile personally identifiable information when you submit the enquiry form on our website. While using our site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personal identifiable information may include but is not limited to your name. We will limit the collection and usage of customer information to the required minimum in order to provide service to our customers. This includes but is not limited to, advising our customers about our products, services and other opportunities, and to administer our business and for compliance with their own legal obligations, including maintaining books & records.</p>
                        <p id="disclaimer">We will permit only authorized employees, who are trained in proper handling of customer information, to access such information such as providing and personalizing the services, communication with you, facilitating the loyalty programs and to accomplish our business purposes. We do not sell, rent or share personally identifiable information made available to us.</p>
                        <p className='m-0 mt-4'><strong>Disclaimer</strong></p>
                        <p>All images (other than the actual images used) are merely artistic conceptualization, indicative in nature and do not replicate the exact product and should not be relied upon as definitive reference.  Landscape amenities, accessories used, and furniture layout are only indicative in nature of how the furnished unit may appear, upon completion, and is merely an Artist’s Rendition only.</p>
                        <p>Any images, plans, layouts, plot sizes, etc., are indicative and are subject to change as decided by Ameya Group or as directed by Competent Authority from time to time, and as deemed necessary. You/ intending purchasers are requested to contact the relevant project office to understand the details regarding the project and only after thorough understanding about the same and taking appropriate advice, take further decision regarding in relation to the projects.</p>
                        <p>We will use and disclose customer information as we believe to be necessary or appropriate: (a) to comply with applicable law, including laws outside your country of residence; (b) to comply with legal process; (c) to respond to requests from public and government authorities, including authorities outside your country of residence and to meet national security or law enforcement requirements; (d) to enforce our terms and conditions; (e) to protect our operations, such as in the event of any reorganization, merger, sale, joint venture, assignment, transfer (f) to protect the rights, privacy, safety or property of Ameya Group; and (g) to allow us to pursue available remedies or limit the damages that we may sustain.</p>
                        <p>We may use and disclose the customer information for any purpose, except where we are not allowed to under applicable law.</p>
                        <p className='m-0 mt-4'><strong>Copyright Notice</strong></p>
                        <p>All content, design, site plans (Intellectual Property) and materials on this Web Site are protected by copyright law and applicable International Treaties and are exclusive property of Ameya Group. No person can use or reproduce or allow any other to use or reproduce any image or logo (such as the name) for any reason without prior written consent from Ameya Group. No person is allowed to retrieve and display the content available on this website for personal, commercial and non-commercial purpose, or cannot reproduce, modify or in any way to make commercial use of the content. The use of content on this website without permission is prohibited. The privacy policy is subject to change and any modification to the privacy policy will be communicated here. You may not otherwise change, reproduce, distribute, publicly perform, or display this material in any way, without prior written approval of Ameya Group.</p>
                        <p className='m-0 mt-4'><strong>General Limitation of Liability</strong></p>
                        <p>Ameya Group cannot be held liable for any loss, monetary or otherwise, resulting from the usage and display of the information on this site or for the lawful use of the customer information. Ameya Group and/or its directors, employees, are not liable for any consequence of any action taken by the viewer relying on such material information herein.</p>
                        <p>This site contains links to other sites and Ameya Group shall not be responsible at any point in time, for the privacy practices or the content of such web sites.</p>
                        <p className='m-0 mt-4'><strong>Notice of changes</strong></p>
                        <p>From time to time, we may change or revise this Internet Privacy Policy.</p>
                        <p className='m-0 mt-4'><strong>Contacting the Website</strong></p>
                        <p>If you have any questions about this privacy statement or about the projects, the practices of this site, or your dealings with this Website, you may reach out to us at:</p>
                        <p>Ameya Commercial Projects Private Limited<br/>Ameya One,Sector - 42, DLF V, DLF Golf Course Road<br/>Gurugram - 122002<br/><strong>CIN: U70200DL2009PTC195340</strong></p>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
  )
}
