import React from 'react';
import { NavLink } from "react-router-dom";

export const Copyright = () => {
  return (
    <>
        <div className='copyright'>
          <div className='container'>
            <div className='row'>
              <div className='copyrightrow d-flex justify-content-between'>
                <p>&copy; 2022 all rights reserved. Ameya &nbsp;|<NavLink to='/privacy-policy'>Privacy Policy</NavLink>|<a href='/privacy-policy#disclaimer'>Disclaimer</a></p>
                <p><a href='https://www.triverseadvertising.com/' target='_blank' rel='noreferrer'>design : triverse</a></p>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
