import React, { useState, useEffect } from "react";
import { Footer } from "./components/Footer";
import Header from "./components/Header";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";

export const Projects = () => {
  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(
        "https://ameyagroup.in/ameya_new_website/api/projects.php"
      );
      if (mounted) {
        setPagedata(response.data);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);

  if (pagedata.length === 0) {
    console.log("no data");
    return null;
  }

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      <div className="breadcrum">
        <div className="container">
          <div className="row">
            <div className="breadcumtab">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Our Projects</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="inprojects">
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div
                className="title"
                data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
                data-aos-delay="300"
              >
                <h2>Projects</h2>
                <p>{pagedata.projects.description}</p>
              </div>
            </div>
            <Tabs defaultIndex={0} onSelect={(index) => console.log(index)}>
              <TabList>
                <Tab
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="300"
                >
                  Ongoing
                </Tab>
                <Tab
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="300"
                >
                  Completed
                </Tab>
              </TabList>
              <TabPanel>
                {pagedata.projects.ongoing ? (
                  <div className="pprojectrow">
                    <div className="valueban pb-lg-5 pb-3">
                      <h4 className="w-fit">commercial projects</h4>
                    </div>
                    <Slider {...settings}>
                      {pagedata.projects.ongoing
                        .filter((project) => project.type === "commercial") // or "residential"
                        .map((data) => {
                          return (
                            <div className="">
                              <div
                                className="pprojectbox w-100 px-3"
                                data-aos="fade-up"
                                data-aos-offset="200"
                                data-aos-duration="600"
                                data-aos-once="true"
                                data-aos-easing="ease-in-sine"
                                data-aos-delay="300"
                              >
                                <Link to={"projects/" + data.slug}>
                                  <div className="pprojectimg">
                                    {data.image ? (
                                      <img src={data.image} alt="" />
                                    ) : null}
                                    <div className="projectinfo">
                                      <ul>
                                        <li>
                                          <strong>LOCATION</strong>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: data.location,
                                            }}
                                          ></p>
                                        </li>
                                        <li>
                                          <strong>PROPERTY TYPE</strong>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: data.type,
                                            }}
                                          ></p>
                                        </li>
                                        <li>
                                          <strong>STATUS</strong>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: data.status,
                                            }}
                                          ></p>
                                        </li>
                                        {data.rerano != "" ? (
                                          <li>
                                            <strong>RERA No.</strong>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: data.rerano,
                                              }}
                                            ></p>
                                          </li>
                                        ) : null}
                                      </ul>
                                    </div>
                                  </div>
                                  <p>
                                    {data.title},{" "}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: data.location,
                                      }}
                                    ></span>
                                  </p>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                ) : null}
                {pagedata.projects.ongoing ? (
                  <div className="pprojectrow pt-lg-5 pt-3">
                    <div className="valueban pb-lg-5 pb-3">
                      <h4 className="w-fit fw-400">residential projects</h4>
                    </div>
                    {pagedata.projects.ongoing
                      .filter((project) => project.type === "residential") // or "residential"
                      .map((data) => {
                        return (
                          <div
                            className="pprojectbox residential "
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                            data-aos-delay="300"
                          >
                            <a href={data.e_link} target="_blank">
                              <div className="pprojectimg">
                                {data.image ? (
                                  <img src={data.image} alt="" />
                                ) : null}
                                <div className="projectinfo">
                                  <ul>
                                    <li>
                                      <strong>LOCATION</strong>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: data.location,
                                        }}
                                      ></p>
                                    </li>
                                    <li>
                                      <strong>PROPERTY TYPE</strong>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: data.type,
                                        }}
                                      ></p>
                                    </li>
                                    <li>
                                      <strong>STATUS</strong>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: data.status,
                                        }}
                                      ></p>
                                    </li>
                                    {data.rerano != "" ? (
                                      <li>
                                        <strong>RERA No.</strong>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: data.rerano,
                                          }}
                                        ></p>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              </div>
                              <p>
                                {data.title},{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data.location,
                                  }}
                                ></span>
                              </p>
                            </a>
                          </div>
                        );
                      })}
                  </div>
                ) : null}
              </TabPanel>
              <TabPanel>
                {pagedata.projects.completed ? (
                  <div className="pprojectrow ">
                    <Slider {...settings}>
                      {pagedata.projects.completed.map((data2) => {
                        return (
                          <div
                            className="pprojectbox w-100 px-3"
                            data-aos="fade-up"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-easing="ease-in-sine"
                            data-aos-delay="300"
                          >
                            <Link to={"projects/" + data2.slug}>
                              <div className="pprojectimg">
                                {data2.image ? (
                                  <img src={data2.image} alt="" />
                                ) : null}
                                <div className="projectinfo">
                                  <ul>
                                    <li>
                                      <strong>LOCATION</strong>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: data2.location,
                                        }}
                                      ></p>
                                    </li>
                                    <li>
                                      <strong>PROPERTY TYPE</strong>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: data2.type,
                                        }}
                                      ></p>
                                    </li>
                                    <li>
                                      <strong>STATUS</strong>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: data2.status,
                                        }}
                                      ></p>
                                    </li>
                                    {data2.rerano != "" ? (
                                      <li>
                                        <strong>RERA No.</strong>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: data2.rerano,
                                          }}
                                        ></p>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              </div>
                              <p>
                                {data2.title},{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data2.location,
                                  }}
                                ></span>
                              </p>
                            </Link>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                ) : null}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
