import React, { useState, useEffect } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import Slider from "react-slick";
import Banner01 from "./images/banner02.jpg";
import Banner02 from "./images/banner01.jpg";
import Banner03 from "./images/banner03.jpg";
import Banner04 from "./images/banner04.jpeg";
import Banner05 from "./images/mbanner.webp";
import Abtameya from "./images/about-ameya.png";
import Haboutimg from "./images/about-us.jpg";
import { Link } from "react-router-dom";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import axios from "axios";
export const Home = () => {
  const [pagedata, setPagedata] = useState([]);
  useEffect(() => {
    let mounted = true;
    const loadData = async () => {
      const response = await axios.get(
        "https://ameyagroup.in/ameya_new_website/api/homes.php"
      );
      if (mounted) {
        setPagedata(response.data);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, []);

  console.log(pagedata);
  if (pagedata.length === 0) {
    console.log("no data");
    return null;
  }

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 8000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="homeeffect position-relative"></div>
      <Header />
      <div
        className="jslider"
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-duration="600"
        data-aos-once="true"
        data-aos-delay="600"
        data-aos-easing="ease-in-sine"
      >
        <div className="arrows"></div>
        <div className="container">
          <div className="row">
            <Slider {...settings}>
              <div>
                <div className="homeslider slide01">
                  <a href="https://www.ameyagroup.in/sapphire-residences" target="_blank">
                    <div className="homcolrt">
                      <img src={Banner04} className="deskop-show" />
                      <img src={Banner05} className="mobile-show" />
                    </div>
                  </a>
                  {/* <div
                    className="homcolft position-absolute"
                    data-aos="fade-up"
                    data-aos-offset="400"
                    data-aos-duration="800"
                    data-aos-once="true"
                    data-aos-delay="800"
                    data-aos-easing="ease-in-sine"
                  >
                    <h2>
                      Now <br />
                      Sector 57 <br />
                      <span>Deserves Its Own Market</span>
                    </h2>
                  </div> */}
                </div>
              </div>
              <div>
                <div className="homeslider slide01">
                  <div className="homcolrt">
                    <img src={Banner01} />
                  </div>
                  <div
                    className="homcolft position-absolute"
                    data-aos="fade-up"
                    data-aos-offset="400"
                    data-aos-duration="800"
                    data-aos-once="true"
                    data-aos-delay="800"
                    data-aos-easing="ease-in-sine"
                  >
                    <h2>
                      Now <br />
                      Sector 57 <br />
                      <span>Deserves Its Own Market</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div>
                <div className="homeslider slide02">
                  <div className="homcolrt">
                    <img src={Banner02} />
                  </div>
                </div>
              </div>
              {/* <div>
      <div className='homeslider slide03'>
        <div className='homcolrt'>
          <img src={Banner03} />
        </div>
        <div className='homcolft position-absolute'>
          <h2>NEIGHBOURHOOD BAZAARS FOR ALL YOUR SHOPPING NEEDS</h2>
        </div>
      </div>
      </div> */}
            </Slider>
          </div>
        </div>
      </div>
      <div className="aboutameya">
        <div className="container">
          <div className="row">
            <div className="aboutcont d-flex align-items-center justify-content-between">
              <div className="aboutleft position-relative">
                <h2
                  className="position-relative"
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-delay="600"
                  data-aos-easing="ease-in-sine"
                >
                  {pagedata.aboutus.hptitle}
                </h2>
                <img
                  src={Abtameya}
                  className="position-absolute top-0 opacity-50"
                  data-aos="flip-up"
                  data-aos-offset="400"
                  data-aos-duration="800"
                  data-aos-once="true"
                  data-aos-delay="800"
                  data-aos-easing="ease-in-sine"
                />
              </div>
              <div
                className="aboutright"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-delay="600"
                data-aos-easing="ease-in-sine"
                dangerouslySetInnerHTML={{ __html: pagedata.aboutus.sdesc }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="hprojects">
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div
                className="title"
                data-aos="zoom-in"
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-easing="ease-in-sine"
                data-aos-delay="100"
              >
                <h2>Projects</h2>
              </div>
              <div
                className="subtitle"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-duration="600"
                data-aos-once="true"
                data-aos-delay="200"
                data-aos-easing="ease-in-sine"
              ></div>
              <div className="projectchart">
                <Tabs defaultIndex={0} onSelect={(index) => console.log(index)}>
                  <TabList
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-delay="200"
                    data-aos-easing="ease-in-sine"
                  >
                    <Tab>Ongoing</Tab>
                    <Tab>Completed</Tab>
                  </TabList>
                  <TabPanel>
                    <div className="projectrow">
                      {/* Ongoing Loop Start  */}
                      {pagedata.projects.ongoing.map((ogData) => {
                        return (
                          <div
                            className="projectbox"
                            data-aos="fade-right"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-delay="200"
                            data-aos-easing="ease-in-sine"
                          >
                            <Link to={"/projects/" + ogData.slug}>
                              <img src={ogData.image} />
                              <h3>
                                {ogData.title}, {ogData.location}
                              </h3>
                            </Link>
                          </div>
                        );
                      })}
                      {/* Ongoing Loop End */}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="projectrow">
                      {/* Ongoing Loop Start  */}
                      {pagedata.projectscomp.completed.map((ogData2) => {
                        return (
                          <div
                            className="projectbox"
                            data-aos="fade-right"
                            data-aos-offset="200"
                            data-aos-duration="600"
                            data-aos-once="true"
                            data-aos-delay="200"
                            data-aos-easing="ease-in-sine"
                          >
                            <Link to={"/projects/" + ogData2.slug}>
                              <img src={ogData2.image} />
                              <h3>
                                {ogData2.title}, {ogData2.location}
                              </h3>
                            </Link>
                          </div>
                        );
                      })}
                      {/* Ongoing Loop End */}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              <div className="col-md-12">
                <Link class="btn-3" to="/projects">
                  <span>View All Projects</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="haboutus">
        <div className="container">
          <div className="row">
            <div className="web-container">
              <div className="haboutrow">
                <div
                  className="haboutimg"
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-duration="600"
                  data-aos-once="true"
                  data-aos-easing="ease-in-sine"
                  data-aos-delay="200"
                >
                  <img src={Haboutimg} />
                </div>
                <div className="haboutcont">
                  <h1
                    data-aos="fade"
                    data-aos-offset="300"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="800"
                  >
                    {pagedata.aboutus.hptitle2}
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="350"
                  >
                    {pagedata.aboutus.sdesc2}
                  </p>
                  <Link
                    class="btn-3"
                    to="/about-ameya"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-duration="600"
                    data-aos-once="true"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay="350"
                  >
                    <span>Read More</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Home;
